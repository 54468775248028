// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-tsx": () => import("/builds/byggemappen/fonn-website/src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-clients-tsx": () => import("/builds/byggemappen/fonn-website/src/templates/clients.tsx" /* webpackChunkName: "component---src-templates-clients-tsx" */),
  "component---src-templates-about-tsx": () => import("/builds/byggemappen/fonn-website/src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-contact-tsx": () => import("/builds/byggemappen/fonn-website/src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-request-demo-tsx": () => import("/builds/byggemappen/fonn-website/src/templates/request-demo.tsx" /* webpackChunkName: "component---src-templates-request-demo-tsx" */),
  "component---src-templates-try-for-free-tsx": () => import("/builds/byggemappen/fonn-website/src/templates/try-for-free.tsx" /* webpackChunkName: "component---src-templates-try-for-free-tsx" */),
  "component---src-templates-pricing-tsx": () => import("/builds/byggemappen/fonn-website/src/templates/pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-index-tsx": () => import("/builds/byggemappen/fonn-website/src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-pages-404-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bestill-en-demo-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/bestill-en-demo.tsx" /* webpackChunkName: "component---src-pages-bestill-en-demo-tsx" */),
  "component---src-pages-index-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kunder-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/kunder.tsx" /* webpackChunkName: "component---src-pages-kunder-tsx" */),
  "component---src-pages-om-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/om.tsx" /* webpackChunkName: "component---src-pages-om-tsx" */),
  "component---src-pages-pris-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/pris.tsx" /* webpackChunkName: "component---src-pages-pris-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-produkt-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/produkt.tsx" /* webpackChunkName: "component---src-pages-produkt-tsx" */),
  "component---src-pages-prov-gratis-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/prov-gratis.tsx" /* webpackChunkName: "component---src-pages-prov-gratis-tsx" */),
  "component---src-pages-tos-tsx": () => import("/builds/byggemappen/fonn-website/src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */)
}

