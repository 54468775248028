module.exports = [{
      plugin: require('/builds/byggemappen/fonn-website/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/byggemappen/fonn-website/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MSPJX3K","includeInDevelopment":false},
    },{
      plugin: require('/builds/byggemappen/fonn-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
